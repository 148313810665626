import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'

import styles from './page.module.css'

function Page (props) {
  const {_rawBody, authors, categories, title, mainImage, publishedAt} = props
  return (
    <>
    {mainImage && mainImage.asset && (
      <div className={styles.mainImage}>
        <img
          src={imageUrlFor(buildImageObj(mainImage))
            .width(1200)
            .auto('format')
            .url()}
          alt={mainImage.alt}
        />
        {console.log(mainImage)}
        {console.log(imageUrlFor(buildImageObj(mainImage)).width(1200).auto('format').url())}
      </div>
    )}
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </Container>
    </article>
    </>
  )
}

export default Page
